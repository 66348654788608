<template>
  <div>
    <b-overlay
      :show="load"
      variant="transparent"
    >
      <b-card>
        <b-button
          v-b-modal.importarEnriquecimento
          variant="primary"
        >
          Importar
        </b-button>
      </b-card>
    </b-overlay>
    <b-modal
      id="importarEnriquecimento"
      ref="importarEnriquecimento"
      title="Enriquecimento em lote"
      ok-title="Enriquecer"
      cancel-title="Cancelar"
      cancel-variant="danger"
      no-close-on-backdrop
      :ok-disabled="file === null"
      @ok="importarEnriquecimentoEmLote"
    >
      <form
        ref="form"
      >
        <b-form-group
          label="Selecione o arquivo"
          label-for="name"
        >
          <b-form-file
            v-model="file"
            placeholder=""
            drop-placeholder="Arquivo"
          />
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BFormFile,
  BFormGroup,
  BCard,
  BOverlay,
} from 'bootstrap-vue'
import axios from '../../../../axios-auth'

export default {
  components: {
    BButton,
    BModal,
    BFormFile,
    BFormGroup,
    BCard,
    BOverlay,
  },
  data() {
    return {
      file: null,
      load: false,
    }
  },
  mounted() {
    this.$refs.importarEnriquecimento.show()
  },
  methods: {
    importarEnriquecimentoEmLote() {
      this.load = true
      const formData = new FormData()
      formData.append('planilha', this.file)
      axios.post('api/v1/clientes/enriquecer_lote', formData)
        .then(() => {
          this.load = false
          this.file = null
          this.$swal({
            title: 'Importação realizada com sucesso!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          this.load = false
          if (error.response) {
            this.$swal({
              title: 'Erro!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
  },
}
</script>
